.home-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-container {
        width: 405.76px;
        color: black;
    }

    .links {
        text-decoration: none;
        color: #eee;
    }
}
