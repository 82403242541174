.task-item-container {
    display: flex;
    justify-content: space-between;
    max-width: 50%;

    @media (max-width: 768px) {
        max-width: 90%;
    }

    .delete {
        padding-top: 1.5px;
        margin-left: 18px;

        &:hover {
            cursor: pointer;
        }
    }
}

/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 1.5px;
}

.checkbox-container-completed {
    @extend .checkbox-container;
    color: #9d9aad;
    text-decoration: line-through;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: linear-gradient(259.93deg, #000000 -41.68%, #00171f 146.85%);
    border: 2px solid #ffffff;
    border-color: #fff;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background: linear-gradient(262.71deg, #00d1ff -30.04%, #00ffa3 124.74%);
}

.checkbox-container input:checked ~ .completed {
    background: #3a3847;
    border-color: #3a3847;
    border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-color: #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
