$sub-color: #b8b8b8;
$main-color: #ffffff;

@mixin shrinkLabel {
    top: -14px;
    font-size: 12px;
    color: $main-color;
}

.custom-input-container {
    position: relative;

    .custom-input {
        background: none;
        color: $sub-color;
        font-size: 18px;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid $sub-color;
        transition: all 0.5s ease;

        &:focus {
            outline: none;
            border-bottom: 1px solid $main-color;
        }

        &:focus ~ .custom-input-label {
            @include shrinkLabel();
        }
    }

    .custom-input-label {
        color: $sub-color;
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 300ms ease all;

        &.shrink {
            @include shrinkLabel();
        }
    }
}
