.tasks-container {
    padding: 60px 85px;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    padding-top: 50px;
    height: 100vh;
    overflow-y: hidden;
    flex: auto;

    h2 {
        margin-bottom: 24px;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .last-tasks {
        flex: 1;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;

        h3 {
            margin-bottom: 18px;
        }

        /* width */
        ::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #ff5e1e;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .tasks-list {
            overflow-y: auto;
            margin-top: 28px;
        }
    }

    .completed-tasks {
        flex: 1;
        padding-top: 28px;

        .tasks-list {
            margin-top: 28px;
        }
    }

    @media (max-width: 1024px) {
        max-width: 60%;
    }

    @media (max-width: 768px) {
        padding: 30px;
        max-width: 100%;
    }
}
