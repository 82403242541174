.add-task-container {
    display: flex;
    align-items: center;

    div:first-child {
        width: 100%;
    }

    .custom-button-container {
        margin-left: 16px;
    }
}
