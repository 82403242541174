$background: #252524;
$primary-text-color: #eee;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background: $background;
    color: $primary-text-color;
}
